/* mentions */ 
  .mentions--multiLine .mentions__control {
    font-size: 16px;
  }
  .mentions--multiLine .mentions__highlighter {
    border: 1px solid transparent;
    min-height: 100px;
    padding: 12px 16px;
  }

  .mentions--multiLine .mentions__highlighter strong{
   background-color: rgb(206, 228, 229);
  }
  .mentions--multiLine .mentions__input {
    background: #FFFFFF;
    border: 1px solid #E2E2EA;
    border-radius: 8px;    
    width: 100%;  
    line-height: 20px;
    min-height: 100px;
    padding: 12px 16px;
    resize: vertical;
    color:#6f7073;   
    
    &[aria-invalid='true']{
      background-color: #FFEEEE;
      border-color: #D64535;
      color: #D64535;
    }

    &:not([aria-invalid='true']):focus {
      border-color: #B2C1D8;
    }
  
    &::placeholder {
      color: #C1C2C4;
    }   
  }

  .mentions__suggestions__list {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
    max-height: 100px;
    overflow-y: auto;
  }
  .mentions__suggestions__item {
    padding: 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
  .mentions__suggestions__item--focused {
    background-color: #677D9E;
    color: #fff;
  }
 